import { BrandCode, RegionCode } from "core/entities";
import { useAPIAll } from "core/hooks/useAPIAll";
import { useProcessBrandRegionData } from "core/hooks/useProcessBrandRegionData";
import { ALL_VALUE } from "config";
import { useTranslation } from "react-i18next";

export function useDMSBrandRegionOptions(
  mode: "library" | "document",
  docLibraryId?: string
) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:useBrandRegionOptions",
  });
  const processBrandRegionData = useProcessBrandRegionData();
  const isLibraryMode = mode === "library";
  const isDocumentMode = !isLibraryMode;
  if (isDocumentMode && !docLibraryId)
    throw new Error('docLibraryId required in "document" mode');
  const libraryAPIKey = isLibraryMode
    ? "/api/shared-entities/organization-unit/brands"
    : null;

  const {
    data: libraryData,
    isError: isLibraryDataError,
    isLoading: isLibraryDataLoading,
  } = useAPIAll<{ brand: BrandCode; regions: RegionCode[] }[]>(libraryAPIKey);

  const documentAPIKey = isDocumentMode
    ? `doc-library/${docLibraryId}/available-brands`
    : null;
  const {
    data: documentData,
    isError: isDocumentDataError,
    isLoading: isDocumentDataLoading,
  } = useAPIAll<{ brand: BrandCode; regions: RegionCode[] }[]>(documentAPIKey);

  const data = isLibraryMode ? libraryData : documentData;

  const {
    brandOptions = [],
    regionOptions = [],
    brandRegionMap = {},
    brandCodes = [],
  } = processBrandRegionData(data);

  if (mode === "library") {
    brandOptions.unshift({ label: t("allBrands"), value: ALL_VALUE });
    regionOptions.unshift({ label: t("allRegions"), value: ALL_VALUE });
  }

  return {
    brandOptions,
    regionOptions,
    brandRegionMap,
    brandCodes,
    data,
    isError: isLibraryMode ? isLibraryDataError : isDocumentDataError,
    isLoading: isLibraryMode ? isLibraryDataLoading : isDocumentDataLoading,
  };
}
