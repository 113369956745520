import { IDocDocumentPermissionDto } from "resources/APIModel";
import LinearProgress from "@mui/material/LinearProgress";
import Box, { BoxProps } from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { PermDisplayItem } from "./PermDisplayItem";
import { useReadablePermissions } from "./useReadablePermissions";

interface DMSPermissionsDisplayProps extends BoxProps {
  permissions: IDocDocumentPermissionDto[];
  docLibraryId: string;
}

export function DmsPermissionsDisplay({
  permissions,
  docLibraryId,
  ...boxProps
}: DMSPermissionsDisplayProps) {
  const readablePerms = useReadablePermissions(permissions, docLibraryId);
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DMSPermissionsDisplay",
  });
  if (!readablePerms)
    return (
      <Box {...boxProps}>
        <LinearProgress />
      </Box>
    );
  const { brands, corporateRoles, departments, regions, shopRoles, shops } =
    readablePerms;

  return (
    <Box {...boxProps}>
      <PermDisplayItem label={t("brands")} tags={brands} />
      <PermDisplayItem label={t("regions")} tags={regions} />
      {departments.length > 0 && (
        <PermDisplayItem label={t("departments")} tags={departments} />
      )}
      {corporateRoles.length > 0 && (
        <PermDisplayItem label={t("corporateRoles")} tags={corporateRoles} />
      )}
      <PermDisplayItem label={t("shops")} tags={shops} />
      <PermDisplayItem label={t("shopRoles")} tags={shopRoles} />
    </Box>
  );
}
