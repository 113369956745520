import { ALL_VALUE } from "config";
import { IDocDocumentPermissionDto } from "resources/APIModel";
import { ViewablePermissionsFormValues } from "../defs";

function getCorporatePermissionsSet({
  brands,
  regions,
  departments,
  corporateRoles,
  toggle,
}: Partial<ViewablePermissionsFormValues>): IDocDocumentPermissionDto[] {
  // handle expanding and filtering department permissions
  if (toggle === "departments") {
    const departmentPerms: IDocDocumentPermissionDto[] = [];
    // expand into all the combinations of department, region, and brand
    departments?.forEach((currentDepartment) => {
      regions?.forEach((currentRegion) => {
        brands?.forEach((currentBrand) => {
          const currentPermission: IDocDocumentPermissionDto = {
            brand: currentBrand,
            region: currentRegion,
            departmentName: currentDepartment,
          };
          departmentPerms.push(currentPermission);
        });
      });
    });
    return departmentPerms;
  }
  const rolePermissions: IDocDocumentPermissionDto[] = [];
  corporateRoles?.forEach((currentRole) => {
    regions?.forEach((currentRegion) => {
      brands?.forEach((currentBrand) => {
        const currentRolePermission: IDocDocumentPermissionDto = {
          brand: currentBrand,
          region: currentRegion,
          roleId: currentRole,
        };
        rolePermissions.push(currentRolePermission);
      });
    });
  });
  return rolePermissions;
}

function getShopPermissionsSet({
  brands,
  regions,
  shops,
  shopRoles,
}: Partial<ViewablePermissionsFormValues>): IDocDocumentPermissionDto[] {
  const shopPerms: IDocDocumentPermissionDto[] = [];
  const isAllShops = shops?.includes(ALL_VALUE);
  if (isAllShops) {
    shopRoles?.forEach((currentShopRole) => {
      regions?.forEach((currentRegion) => {
        brands?.forEach((currentBrand) => {
          shopPerms.push({
            brand: currentBrand,
            region: currentRegion,
            fnShopId: ALL_VALUE,
            shopRoleId: currentShopRole,
          });
        });
      });
    });
    return shopPerms;
  }
  shops?.forEach((currentShop) => {
    shopRoles?.forEach((currentShopRole) => {
      shopPerms.push({
        brand: ALL_VALUE,
        region: ALL_VALUE,
        fnShopId: currentShop,
        shopRoleId: currentShopRole,
      });
    });
  });
  return shopPerms;
}

export function formStateToPermissionSet({
  brands,
  corporateRoles,
  departments,
  regions,
  shopRoles,
  shops,
  toggle,
  isVisibleToShops,
}: ViewablePermissionsFormValues): IDocDocumentPermissionDto[] {
  if (brands.length === 0 || regions.length === 0) return [];
  if (departments.length === 0 && corporateRoles.length === 0) return [];

  const corporatePerms = getCorporatePermissionsSet({
    brands,
    regions,
    departments,
    corporateRoles,
    toggle,
  });
  const shopPerms = isVisibleToShops
    ? getShopPermissionsSet({ brands, regions, shopRoles, shops, toggle })
    : [];
  return [...corporatePerms, ...shopPerms];
}
