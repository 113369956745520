import Typography from "@mui/material/Typography";
import clone from "just-clone";
import { useAPIPutJSON } from "core/hooks/useAPIPutJSON";
import { useToaster } from "core/hooks/useToaster";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LibraryFormValues } from "features/DMS/entities";
import { arrayToObject } from "core/utils/arrayToObject";
import {
  useLibraryById,
  useDMSFormErrorHandler,
  useManageableLibraries,
  useInfiniteDocuments,
  useCategories,
} from "features/DMS/hooks";
import { LibraryForm, defaultValues } from "./LibraryForm";

interface LibraryEditProps {
  cancelOnClick: () => void;
  id: string;
  canManageLibraryContents: boolean;
}

export function LibraryEdit({
  cancelOnClick,
  id,
  canManageLibraryContents,
}: LibraryEditProps) {
  const { data, isLoading, isError, mutate } = useLibraryById(id);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // TODO update SWR Package to v2, so we can use the global mutate
  // https://swr.vercel.app/docs/mutation#mutate-multiple-items
  const { mutate: mutateDocs } = useInfiniteDocuments({
    parentId: id,
    mode: "manage",
    canManageLibraryContents,
  });
  const { mutate: mutateCategories } = useCategories({
    mode: "manage",
    parentId: id,
    canManageLibraryContents,
  });
  const formMethods = useForm<LibraryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { mutate: mutateManageableLibraries } = useManageableLibraries();
  const { errorToast, successToast } = useToaster();
  const apiPut = useAPIPutJSON();
  const { errorHandler, scrollToRef } = useDMSFormErrorHandler(
    formMethods,
    "LibraryForm"
  );

  useEffect(() => {
    if (!isLoading && !isError && data) {
      const { translations: translationsArray, ...rest } = clone(data);
      if (!Array.isArray(translationsArray)) return;
      const translations = arrayToObject(
        translationsArray.map((translation) => ({
          ...translation,
        })),
        "transKey"
      );
      const editFormData = { translations, ...rest };
      formMethods.reset(editFormData);
    }
  }, [data, isLoading, isError, formMethods]);

  const { handleSubmit } = formMethods;
  const { t } = useTranslation("DocumentManagementSystem");

  async function onFormSubmit(submitData: LibraryFormValues) {
    setIsSubmitting(true);
    try {
      const { translations: translationsObject, ...rest } = submitData;
      const translations = Object.values(translationsObject);
      const updatedLibrary = await apiPut(`doc-library/${id}`, {
        translations,
        ...rest,
      });

      const mutateLibrariesCallback: Parameters<
        typeof mutateManageableLibraries
      >[0] = (libraries) => {
        if (!libraries) return { items: [updatedLibrary], totalCount: 1 };
        const { items, totalCount } = libraries;
        const updatedItems = items.map((library) =>
          library.id === updatedLibrary.id ? updatedLibrary : library
        );
        return {
          items: updatedItems,
          totalCount: Number.isInteger(totalCount) ? totalCount : 1,
        };
      };

      await Promise.allSettled([mutateDocs(), mutateCategories()]);
      await mutate();
      await mutateManageableLibraries(mutateLibrariesCallback);
      successToast(t("Next:DocumentManagementSystem:LibraryEdit.PUTSuccess"));
      cancelOnClick();
    } catch {
      setIsSubmitting(false);
      errorToast(t("Next:DocumentManagementSystem:LibraryEdit.PUTError"));
    }
  }

  const onSubmit = handleSubmit(onFormSubmit, errorHandler);
  return (
    <>
      <Typography
        variant="h3"
        textAlign="center"
        color="primary.main"
        my={3}
        ref={scrollToRef}
      >
        {t("Next:DocumentManagementSystem:LibraryEdit.title")}
      </Typography>
      <LibraryForm
        mode="edit"
        isLoading={isLoading}
        translations={data?.translations}
        formMethods={formMethods}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        cancelOnClick={cancelOnClick}
      />
    </>
  );
}
