import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import {
  ReactHookViewablePermissions,
  ResetHandle,
} from "./ReactHookDMSViewablePermissions";
import { useViewableDMSPermissionOptions } from "./useViewableDMSPermissionOptions";

interface DMSViewablePermissionsManagementProps {
  docLibraryId: string;
}

export const DMSViewablePermissionsManagement = forwardRef<
  ResetHandle,
  DMSViewablePermissionsManagementProps
>(({ docLibraryId }, ref) => {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:ViewablePermissionsManagement",
  });

  const {
    brandOptions,
    corporateRoleOptions,
    departmentBrandMap = {},
    departmentOptions,
    isLoading,
    regionOptions,
    shopOptions,
    shopRoleOptions,
    brandRegionMap,
    shopBrandRegionMap,
  } = useViewableDMSPermissionOptions(docLibraryId);

  return (
    <ReactHookViewablePermissions
      name="permissions"
      ref={ref}
      isLoading={isLoading}
      loadingText={t("loadingText")}
      brandOptions={brandOptions}
      regionOptions={regionOptions}
      departmentOptions={departmentOptions}
      corporateRoleOptions={corporateRoleOptions}
      shopOptions={shopOptions}
      shopRoleOptions={shopRoleOptions}
      brandsByDepartment={departmentBrandMap}
      brandRegionMap={brandRegionMap}
      shopBrandRegionMap={shopBrandRegionMap}
    />
  );
});

DMSViewablePermissionsManagement.displayName =
  "DMSViewablePermissionsManagement";
