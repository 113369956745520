import { useEffect } from "react";
import clone from "just-clone";
import Typography from "@mui/material/Typography";
import { useAPI } from "core/hooks/useAPI";
import { useAPIPutJSON } from "core/hooks/useAPIPutJSON";
import { useToaster } from "core/hooks/useToaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IDocCategoryDto } from "resources/APIModel";
import { arrayToObject } from "core/utils/arrayToObject";
import { CategoryForm, defaultValues } from "./CategoryForm";
import { useCategories, useDMSFormErrorHandler } from "../hooks";
import { ManagePaper } from "./ManagePaper";
import { CategoryFormDetailsSkeleton } from "./CategoryFormDetailsSkeleton";
import { CategoryFormValues } from "../entities";

interface SubCategoryEditProps {
  cancelOnClick: () => void;
  parentId: string;
  id: string;
  canManageLibraryContents: boolean;
}

export function SubCategoryEdit({
  cancelOnClick,
  parentId,
  id,
  canManageLibraryContents,
}: SubCategoryEditProps) {
  const formMethods = useForm<CategoryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    formState: { isSubmitting },
  } = formMethods;
  const { t } = useTranslation("DocumentManagementSystem");
  const apiPut = useAPIPutJSON();
  const { successToast, errorToast } = useToaster();
  const { mutate: mutateParent } = useCategories({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });

  const { errorHandler } = useDMSFormErrorHandler(formMethods, "CategoryForm");
  const { isLoading, isError, data, mutate } = useAPI<IDocCategoryDto>(
    `doc-category/${id}`
  );

  useEffect(() => {
    if (!isLoading && !isError && data) {
      const { translations: translationsArray, ...rest } = clone(data);
      const translations = arrayToObject(translationsArray, "transKey");
      formMethods.reset({ translations, ...rest });
    }
  }, [data, isLoading, isError, formMethods]);

  const { handleSubmit } = formMethods;

  async function onFormSubmit(submitData: CategoryFormValues) {
    try {
      const { translations: translationObject, ...rest } = submitData;
      const translations = Object.values(translationObject);
      const updatedSubCategory = await apiPut(`doc-category/${id}`, {
        translations,
        ...rest,
      });
      mutate(updatedSubCategory);
      mutateParent((categories) => {
        const { items = [], totalCount = 0 } = categories || {};
        const updatedItems = items.map((category) =>
          category.id === updatedSubCategory.id ? updatedSubCategory : category
        );
        return { items: updatedItems, totalCount };
      });
      successToast(
        t("Next:DocumentManagementSystem:SubCategoryEdit.PUTSuccess")
      );
      cancelOnClick();
    } catch {
      errorToast(t("Next:DocumentManagementSystem:SubCategoryEdit.PUTError"));
    }
  }

  const onSubmit = handleSubmit(onFormSubmit, errorHandler);

  return (
    <>
      <Typography variant="h3" textAlign="center" color="primary.main" my={3}>
        {t("Next:DocumentManagementSystem:SubCategoryEdit.title")}
      </Typography>
      {isLoading ? (
        <ManagePaper
          sectionTitle={t(
            "Next:DocumentManagementSystem:CategoryForm.detailsTitle"
          )}
        >
          <CategoryFormDetailsSkeleton />
        </ManagePaper>
      ) : (
        <CategoryForm
          cancelOnClick={cancelOnClick}
          onSubmit={onSubmit}
          formMethods={formMethods}
          mode="edit"
          formType="SubCategoryForm"
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
}
