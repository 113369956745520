import { IDocDocumentPermissionDto } from "resources/APIModel";
import { useViewableDMSPermissionOptions } from "../PermissionManagement";
import { permissionSetToFormState } from "../PermissionManagement/ViewablePermissionsManagement/utils";

export interface ReadablePermissions {
  brands: string[];
  regions: string[];
  departments: string[];
  corporateRoles: string[];
  shops: string[];
  shopRoles: string[];
}

export function useReadablePermissions(
  permissions: IDocDocumentPermissionDto[],
  docLibraryId: string
): ReadablePermissions | undefined {
  const {
    brands: brandCodes,
    corporateRoles: corporateRoleIds,
    departments: departmentIds,
    regions: regionCodes,
    shopRoles: shopRoleIds,
    shops: shopIds,
  } = permissionSetToFormState(permissions);
  const {
    brandOptions,
    isLoading,
    corporateRoleOptions,
    departmentOptions,
    regionOptions,
    shopOptions,
    shopRoleOptions,
  } = useViewableDMSPermissionOptions(docLibraryId);
  if (isLoading) return undefined;

  function mapLabels(
    values: string[],
    options: { label: string; value: string }[]
  ) {
    return values
      .map((val) => options.find((op) => op.value === val)?.label)
      .filter((label): label is string => typeof label === "string");
  }
  const brands = mapLabels(brandCodes, brandOptions);
  const regions = mapLabels(regionCodes, regionOptions);
  const departments = mapLabels(departmentIds, departmentOptions);
  const corporateRoles = mapLabels(corporateRoleIds, corporateRoleOptions);
  const shops = mapLabels(shopIds, shopOptions);
  const shopRoles = mapLabels(shopRoleIds, shopRoleOptions);
  return { brands, regions, departments, corporateRoles, shops, shopRoles };
}
