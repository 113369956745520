import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useAPIDelete } from "core/hooks/useAPIDelete";
import { useMenuItems } from "core/hooks/useMenuItems";
import { useToaster } from "core/hooks/useToaster";
import { useInfiniteDocuments } from "../hooks";

export interface DocumentDeleteProps {
  id: string;
  parentId: string;
  cancelOnClick: () => void;
  canManageLibraryContents: boolean;
}

export function DocumentDelete({
  cancelOnClick = () => {},
  id,
  parentId,
  canManageLibraryContents,
}: DocumentDeleteProps) {
  const { t } = useTranslation("DocumentManagementSystem");
  const { mutate: mutateManageable } = useInfiniteDocuments({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const { mutate: mutateViewable } = useInfiniteDocuments({
    parentId,
    mode: "view",
    canManageLibraryContents,
  });

  const { mutate: mutateMenuItems } = useMenuItems();
  const { successToast, errorToast } = useToaster();
  const apiDelete = useAPIDelete();

  async function onDeleteClick() {
    try {
      await apiDelete(`doc-document/${id}`);

      mutateManageable((currentResponses) => {
        if (!Array.isArray(currentResponses)) return [];
        return currentResponses.map(({ items, totalCount }) => {
          const filteredItems = items.filter((doc) => doc.id !== id);
          return { items: filteredItems, totalCount: totalCount - 1 };
        });
      });
      mutateViewable();
      mutateMenuItems();
      successToast(
        t("Next:DocumentManagementSystem:DocumentDelete.DELETESuccess")
      );
      cancelOnClick();
    } catch {
      errorToast(t("Next:DocumentManagementSystem:DocumentDelete.DELETEError"));
    }
  }

  return (
    <Box>
      <Typography variant="h5" mb={4} textAlign="center" maxWidth="65ch">
        {t("Next:DocumentManagementSystem:DocumentDelete.message", { id })}
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button
          variant="outlined"
          color="warning"
          onClick={cancelOnClick}
          size="large"
        >
          {t("Next:DocumentManagementSystem:DeleteLibrary.cancelButton")}
        </Button>
        <Button
          variant="contained"
          color="error"
          size="large"
          onClick={onDeleteClick}
        >
          {t("Next:DocumentManagementSystem:DeleteLibrary.deleteButton")}
        </Button>
      </Stack>
    </Box>
  );
}
