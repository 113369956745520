import { useRouter } from "next/router";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { useTranslation } from "react-i18next";

export interface PermsDisplayItemProps {
  label: string;
  tags: string[];
  displayMax?: number;
}

export function PermDisplayItem({
  label,
  tags,
  displayMax = 2,
}: PermsDisplayItemProps) {
  const { locale } = useRouter();
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:PermDisplay",
  });
  const formatter = new Intl.ListFormat(locale, {
    type: "conjunction",
  });
  const tooltipTitle = formatter.format(tags);
  const [visibleTags, hiddenTags] = [
    tags.slice(0, displayMax),
    tags.slice(displayMax),
  ];
  const tagsToDisplay = hiddenTags.length
    ? [...visibleTags, t("moreCount", { count: hiddenTags.length })]
    : visibleTags;
  const permList = formatter.format(tagsToDisplay);
  const text = tags.length ? permList : t("none");

  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary">
        {label}:
      </Typography>
      <Tooltip
        title={hiddenTags.length > 0 ? tooltipTitle : ""}
        placement="bottom-start"
      >
        <Typography>{text}</Typography>
      </Tooltip>
    </Box>
  );
}
