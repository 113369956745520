import { forwardRef, useImperativeHandle } from "react";
import { ComponentSwitcher } from "core/components/ComponentSwitcher";
import { SwitchySwitch } from "core/components/SwitchySwitch";
import { ReactHookCheckbox } from "core/components/ReactHookFormComponents/ReactHookCheckbox";
import { ReactHookViewablePermissionMultiselect } from "core/components/ReactHookFormComponents/ReactHookViewablePermissionMultiselect";
import { ALL_VALUE } from "config";
import { Control, useController, useForm, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import { IDocDocumentPermissionDto } from "resources/APIModel";
import { ViewablePermissionsFormValues } from "./defs";
import {
  permissionSetToFormState,
  formStateToPermissionSet,
  extractInvalidPermissions,
} from "./utils";
import { filterRegionOptionsBySelectedBrands } from "./utils/filterRegionOptionsBySelectedBrands";
import { filterDepartmentOptionsBySelectedBrands } from "./utils/filterDepartmentOptionsBySelectedBrands";
import { filterShopOptionsBySelectedBrandsAndRegions } from "./utils/filterShopOptionsBySelectedBrandsAndRegions";

type SelectOption = { label: string; value: string };
export interface ReactHookViewablePermissionsProps {
  name: string;
  isLoading?: boolean;
  control?: Control<any, any>;
  loadingText: string;
  brandOptions: SelectOption[];
  regionOptions: SelectOption[];
  departmentOptions: SelectOption[];
  corporateRoleOptions: SelectOption[];
  shopOptions: SelectOption[];
  shopRoleOptions: SelectOption[];
  brandsByDepartment: Record<string, string[]>;
  brandRegionMap: Record<string, string[]>;
  shopBrandRegionMap: Record<string, { brand: string; region: string }>;
}

export type ResetHandle = {
  reset: (fieldValue: IDocDocumentPermissionDto[]) => void;
};

export const ReactHookViewablePermissions = forwardRef<
  ResetHandle,
  ReactHookViewablePermissionsProps
>((props, ref) => {
  const {
    name,
    control,
    isLoading = false,
    loadingText,
    brandOptions,
    regionOptions,
    departmentOptions,
    corporateRoleOptions,
    shopOptions,
    shopRoleOptions,
    brandsByDepartment,
    brandRegionMap,
    shopBrandRegionMap,
  } = props;
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:ReactHookDMSViewablePermissions",
  });
  const { t: tCore } = useTranslation("Fixhub");

  // this form is used to manage the internal state of the component
  const {
    control: internalControl,
    getValues,
    setValue,
    reset,
    watch,
    trigger,
    clearErrors,
  } = useForm<ViewablePermissionsFormValues>({
    defaultValues: {
      toggle: "departments",
      isVisibleToShops: true,
      brands: [],
      regions: [],
      departments: [ALL_VALUE],
      corporateRoles: [ALL_VALUE],
      shops: [ALL_VALUE],
      shopRoles: [ALL_VALUE],
    },
    mode: "onChange",
  });

  // this useController broadcasts it's value to the parent form via the onChange function
  const { isSubmitted, isSubmitting } = useFormState({ control });
  const {
    field: { onChange, value: permissionSet },
  } = useController({
    name,
    control,
    defaultValue: [],
    rules: {
      validate: async () => {
        const isValid = await trigger();
        if (!isSubmitted && !isSubmitting) {
          clearErrors();
        }
        return isValid;
      },
    },
  });

  const [selectedBrands, selectedRegions, toggle, isVisibleToShops] = watch([
    "brands",
    "regions",
    "toggle",
    "isVisibleToShops",
  ]);

  const filteredRegionOptions = filterRegionOptionsBySelectedBrands(
    selectedBrands,
    regionOptions,
    brandRegionMap
  );
  const filteredDepartmentOptions = filterDepartmentOptionsBySelectedBrands(
    selectedBrands,
    departmentOptions,
    brandsByDepartment
  );
  const filteredShopOptions = filterShopOptionsBySelectedBrandsAndRegions(
    selectedBrands,
    selectedRegions,
    shopOptions,
    shopBrandRegionMap
  );

  const invalidPermissions = extractInvalidPermissions(
    permissionSet,
    brandRegionMap,
    brandsByDepartment
  );
  // expose reset function to sync the internal form with current value, will be used when parent data is loaded;
  useImperativeHandle(
    ref,
    () => ({
      reset: (permissionsFieldValue: IDocDocumentPermissionDto[]) => {
        const newFormState = permissionSetToFormState(permissionsFieldValue);
        reset(newFormState);
      },
    }),
    [reset]
  );

  /**
   * Updates the parent form state with the calculated fields
   */
  function onChangeCallback() {
    const formState = getValues();
    const newPermissionSet = formStateToPermissionSet(formState);
    onChange(newPermissionSet);
  }

  function shouldClearOnChange(
    selectedBrandsOrRegions: string[],
    selectionsToPotentiallyClear: string[]
  ) {
    if (
      selectedBrandsOrRegions.length === 0 ||
      selectedBrandsOrRegions.includes(ALL_VALUE) ||
      selectionsToPotentiallyClear.includes(ALL_VALUE)
    ) {
      return false;
    }
    return true;
  }

  function brandChangeCallBack() {
    const {
      brands: currentlySelectedBrands,
      regions: currentlySelectedRegions,
      departments: selectedDepartments,
      corporateRoles: selectedCorporateRoles,
      shops: selectedShops,
      shopRoles: selectedShopRoles,
    } = getValues();
    const shouldClearRegions = shouldClearOnChange(
      currentlySelectedBrands,
      currentlySelectedRegions
    );
    const shouldClearDepartments = shouldClearOnChange(
      currentlySelectedBrands,
      selectedDepartments
    );
    const shouldClearShops = shouldClearOnChange(
      currentlySelectedBrands,
      selectedShops
    );
    const shouldClearCorporateRoles = shouldClearOnChange(
      currentlySelectedBrands,
      selectedCorporateRoles
    );
    const shouldClearShopRoles = shouldClearOnChange(
      currentlySelectedBrands,
      selectedShopRoles
    );
    if (shouldClearRegions) setValue("regions", []);
    if (shouldClearDepartments) setValue("departments", []);
    if (shouldClearShops) setValue("shops", []);
    if (shouldClearCorporateRoles) setValue("corporateRoles", []);
    if (shouldClearShopRoles) setValue("shopRoles", []);
    onChangeCallback();
  }

  function regionChangeCallBack() {
    const {
      brands: currentlySelectedBrands,
      departments: selectedDepartments,
      corporateRoles: selectedCorporateRoles,
      shops: selectedShops,
      shopRoles: selectedShopRoles,
    } = getValues();

    const shouldClearDepartments = shouldClearOnChange(
      currentlySelectedBrands,
      selectedDepartments
    );
    const shouldClearShops = shouldClearOnChange(
      currentlySelectedBrands,
      selectedShops
    );
    const shouldClearCorporateRoles = shouldClearOnChange(
      currentlySelectedBrands,
      selectedCorporateRoles
    );
    const shouldClearShopRoles = shouldClearOnChange(
      currentlySelectedBrands,
      selectedShopRoles
    );
    if (shouldClearDepartments) setValue("departments", []);
    if (shouldClearShops) setValue("shops", []);
    if (shouldClearCorporateRoles) setValue("corporateRoles", []);
    if (shouldClearShopRoles) setValue("shopRoles", []);
    onChangeCallback();
  }
  const {
    brand: invalidBrand,
    region: invalidRegion,
    departmentName: invalidDepartment,
  } = invalidPermissions[0] || {};
  const invalidRegionWarning = t("invalidRegionWarning", {
    brand: tCore(`Brand:BrandName.${invalidBrand}`),
    region: tCore(`Region:RegionName.${invalidRegion}`),
  });
  const invalidDepartmentWarning = t("invalidDepartmentWarning", {
    brand: tCore(`Brand:BrandName.${invalidBrand}`),
    department: invalidDepartment,
  });
  const warningMessage =
    invalidRegion === ALL_VALUE
      ? invalidDepartmentWarning
      : invalidRegionWarning;
  return (
    <Stack gap={2}>
      <Typography variant="h5" sx={{ mt: 2 }}>
        {t("visibility")}
      </Typography>
      <Collapse in={invalidPermissions.length > 0}>
        <Typography
          sx={{ whiteSpace: "pre-wrap" }}
          variant="caption"
          color="warning.main"
        >
          {warningMessage}
        </Typography>
      </Collapse>
      <Stack direction="row" spacing={2}>
        <ReactHookViewablePermissionMultiselect
          sx={{ flex: 1 }}
          testId="brand-select"
          required
          control={internalControl}
          name="brands"
          label={t("brands")}
          options={brandOptions}
          loading={isLoading}
          loadingText={loadingText}
          onChangeCallback={brandChangeCallBack}
        />
        <ReactHookViewablePermissionMultiselect
          sx={{ flex: 1 }}
          testId="region-select"
          required
          control={internalControl}
          name="regions"
          label={t("regions")}
          options={filteredRegionOptions}
          loading={isLoading}
          loadingText={loadingText}
          onChangeCallback={regionChangeCallBack}
        />
      </Stack>
      <Divider textAlign="left" sx={{ mt: 2 }}>
        <Typography variant="body1">{t("corporate")}</Typography>
      </Divider>
      <SwitchySwitch
        testId="department-role-toggle"
        isPositionOne={toggle === "departments"}
        positionOneText={t("switchDepartmentText")}
        positionTwoText={t("switchRoleText")}
        switchOnChange={(_, checked) => {
          const newTogglePosition = checked ? "roles" : "departments";
          setValue("toggle", newTogglePosition);
          onChangeCallback();
        }}
      />
      <ComponentSwitcher
        switchPosition={toggle === "departments"}
        ComponentOne={
          <ReactHookViewablePermissionMultiselect
            sx={{ flex: 1 }}
            testId="department-select"
            required={toggle === "departments"}
            control={internalControl}
            name="departments"
            label={t("departments")}
            options={filteredDepartmentOptions}
            loading={isLoading}
            loadingText={loadingText}
            onChangeCallback={onChangeCallback}
          />
        }
        ComponentTwo={
          <ReactHookViewablePermissionMultiselect
            sx={{ flex: 1 }}
            required={toggle === "roles"}
            testId="corporate-role-select"
            control={internalControl}
            name="corporateRoles"
            label={t("corporateRoles")}
            options={corporateRoleOptions}
            loading={isLoading}
            loadingText={loadingText}
            onChangeCallback={onChangeCallback}
          />
        }
      />
      <Divider textAlign="left" sx={{ mt: 2 }}>
        <Typography variant="body1">{t("shop")}</Typography>
      </Divider>
      <ReactHookCheckbox
        sx={{ justifyContent: "start" }}
        control={internalControl}
        onChangeCallback={onChangeCallback}
        name="isVisibleToShops"
        label={t("isVisibleToShops")}
        testId="is-visible-to-shops-checkbox"
      />

      <Stack direction="row" spacing={2}>
        <ReactHookViewablePermissionMultiselect
          sx={{ flex: 1 }}
          testId="shop-select"
          control={internalControl}
          required={isVisibleToShops}
          disabled={!isVisibleToShops}
          name="shops"
          label={t("shops")}
          options={filteredShopOptions}
          loading={isLoading}
          loadingText={loadingText}
          onChangeCallback={onChangeCallback}
        />
        <ReactHookViewablePermissionMultiselect
          sx={{ flex: 1 }}
          testId="shop-role-select"
          required={isVisibleToShops}
          control={internalControl}
          disabled={!isVisibleToShops}
          name="shopRoles"
          label={t("shopRoles")}
          options={shopRoleOptions}
          loading={isLoading}
          loadingText={loadingText}
          onChangeCallback={onChangeCallback}
        />
      </Stack>
    </Stack>
  );
});

ReactHookViewablePermissions.displayName = "ReactHookViewablePermissions";
