import { forwardRef, FormEventHandler } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { FormProvider, UseFormReturn } from "react-hook-form";
import Stack from "@mui/material/Stack";
import { ReactHookDateRangePicker } from "core/components/ReactHookFormComponents/ReactHookDateRangePicker";
import { DocumentFormValues, TranslationKey } from "features/DMS/entities";
import { DMSFormButtons } from "../DMSFormButtons";
import { LibraryFormDetailsSkeleton } from "../LibraryFormDetailsSkeleton";
import { ManagePaper } from "../ManagePaper";
import { DocumentTranslationsFields } from "../TranslationsFields";
import {
  ResetHandle,
  DMSViewablePermissionsManagement,
} from "../PermissionManagement/ViewablePermissionsManagement";

export interface DocumentFormProps {
  componentName: "DocumentAdd" | "DocumentEdit";
  formMethods: UseFormReturn<DocumentFormValues>;
  cancelOnClick: () => void;
  clearOnClick?: () => void;
  onSubmit: FormEventHandler<HTMLFormElement>;
  docLibraryId: string;
  isLoading?: boolean;
}

export const DocumentForm = forwardRef<ResetHandle, DocumentFormProps>(
  (
    {
      componentName,
      formMethods,
      onSubmit,
      docLibraryId,
      clearOnClick,
      cancelOnClick,
      isLoading,
    },
    ref
  ) => {
    const { t } = useTranslation("DocumentManagementSystem");
    const testId =
      componentName === "DocumentAdd"
        ? "document-add-form"
        : "document-edit-form";
    return (
      <FormProvider {...formMethods}>
        <Box data-testid={testId} component="form" onSubmit={onSubmit}>
          <Typography
            variant="h3"
            textAlign="center"
            color="primary.main"
            my={3}
          >
            {t(`Next:DocumentManagementSystem:${componentName}.title`)}
          </Typography>
          <ManagePaper
            sectionTitle={t(
              `Next:DocumentManagementSystem:DocumentForm.detailsTitle`
            )}
          >
            {isLoading ? (
              <LibraryFormDetailsSkeleton />
            ) : (
              <>
                <Stack spacing={2}>
                  <DocumentTranslationsFields />
                  <ReactHookDateRangePicker
                    slotProps={{
                      textField: { sx: { display: "flex", gap: 2 } },
                    }}
                    fromName="availableFrom"
                    toName="availableTo"
                    fromLabel={t(
                      "Next:DocumentManagementSystem:DocumentForm.availableFrom"
                    )}
                    toLabel={t(
                      "Next:DocumentManagementSystem:DocumentForm.availableTo"
                    )}
                  />
                </Stack>
                <Divider sx={{ mt: 4, mb: 2 }} />
                <DMSViewablePermissionsManagement
                  docLibraryId={docLibraryId}
                  ref={ref}
                />
              </>
            )}
          </ManagePaper>
          <DMSFormButtons
            mode={componentName === "DocumentAdd" ? "add" : "edit"}
            clearOnClick={clearOnClick}
            formType="DocumentForm"
            cancelOnClick={cancelOnClick}
            isSubmitting={formMethods.formState.isSubmitting}
          />
        </Box>
      </FormProvider>
    );
  }
);
DocumentForm.displayName = "DocumentForm";

export const defaultValues: DocumentFormValues = {
  availableFrom: new Date(),
  availableTo: null,
  attachments: {
    [TranslationKey.En]: {
      id: "",
      transKey: TranslationKey.En,
      documentLink: "",
      fileId: "",
      fileName: "",
      thumbnailId: "",
      thumbnailName: "",
    },
  },
  translations: {
    [TranslationKey.En]: {
      id: "",
      isDefault: true,
      transKey: TranslationKey.En,
      name: "",
      description: "",
      tags: [],
    },
  },
  isDocumentUpload: true,
  permissions: [],
};
