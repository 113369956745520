import { useEffect, useRef } from "react";
import clone from "just-clone";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAPIPutJSON } from "core/hooks/useAPIPutJSON";
import { useMenuItems } from "core/hooks/useMenuItems";
import { useToaster } from "core/hooks/useToaster";
import {
  useDMSFormErrorHandler,
  useDocumentById,
  useInfiniteDocuments,
  useViewableDocuments,
} from "features/DMS/hooks";
import { DocumentFormValues, IDocDocumentDto } from "features/DMS/entities";
import { arrayToObject } from "core/utils/arrayToObject";
import { DocumentForm, defaultValues } from "./DocumentForm";
import { processDocumentFormValues } from "./processDocumentFormValues";
import {
  ResetHandle,
  useViewableDMSPermissionOptions,
} from "../PermissionManagement/ViewablePermissionsManagement";

interface DocumentEditProps {
  id: string;
  parentId: string;
  docLibraryId: string;
  cancelOnClick: () => void;
  canManageLibraryContents: boolean;
}

export function DocumentEdit({
  cancelOnClick,
  parentId,
  id,
  docLibraryId,
  canManageLibraryContents,
}: DocumentEditProps) {
  const formMethods = useForm<DocumentFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { handleSubmit } = formMethods;
  const { isLoading: isPermOptionsLoading } =
    useViewableDMSPermissionOptions(docLibraryId);
  const {
    data: document,
    isLoading: isDocumentDataLoading,
    isError,
    mutate,
  } = useDocumentById(id);
  const { mutate: mutateManageableDocuments } = useInfiniteDocuments({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const { mutate: mutateViewableDocuments } = useInfiniteDocuments({
    parentId,
    mode: "view",
    canManageLibraryContents,
  });
  const isLoading = isPermOptionsLoading || isDocumentDataLoading;
  const { t } = useTranslation("DocumentManagementSystem");
  const apiPutJson = useAPIPutJSON();
  const { errorToast, successToast } = useToaster();
  const { mutate: mutateMenuItems } = useMenuItems();
  const { mutate: mutateDMSWidgetDocuments } = useViewableDocuments();
  const ref = useRef<ResetHandle | null>(null);
  const { errorHandler } = useDMSFormErrorHandler(formMethods, "DocumentForm");

  useEffect(() => {
    if (!isLoading && !isError && document) {
      const {
        translations: translationsArray,
        attachments: attachmentsArray,
        permissions,
        availableFrom,
        availableTo,
        ...rest
      } = clone(document);

      const isDocumentUpload = !rest.documentLink;
      const translations =
        (translationsArray &&
          arrayToObject(
            translationsArray.map((obj) => ({ ...obj })),
            "transKey"
          )) ||
        {};
      const attachments =
        (attachmentsArray &&
          arrayToObject(
            attachmentsArray.map((obj) => ({ ...obj })),
            "transKey"
          )) ||
        {};
      formMethods.reset({
        translations,
        attachments,
        isDocumentUpload,
        permissions,
        availableFrom:
          typeof availableFrom === "string" ? new Date(availableFrom) : null,
        availableTo:
          typeof availableTo === "string" ? new Date(availableTo) : null,
        ...rest,
      });
      // update internal permissions form
      if (typeof ref.current?.reset === "function")
        ref.current.reset(permissions ?? []);
    }
  }, [isLoading, isError, document, formMethods]);

  async function onValid(data: DocumentFormValues) {
    try {
      const putData = processDocumentFormValues(data, parentId);
      const updatedDocument = (await apiPutJson(
        `doc-document/${id}`,
        putData
      )) as IDocDocumentDto;
      mutate({ ...updatedDocument, permissions: putData.permissions });

      mutateManageableDocuments((currentResponses) => {
        if (!Array.isArray(currentResponses)) return [];
        if (!Array.isArray(currentResponses?.[0]?.items)) {
          return [{ items: [updatedDocument], totalCount: 1 }];
        }
        return currentResponses.map(({ items, totalCount }) => {
          const newItems = items.map((doc) =>
            doc.id === updatedDocument.id
              ? { ...updatedDocument, permissions: putData.permissions }
              : doc
          );
          return { items: newItems, totalCount };
        });
      });

      mutateMenuItems();
      mutateDMSWidgetDocuments();
      mutateViewableDocuments();
      successToast(t("Next:DocumentManagementSystem:DocumentEdit.PUTSuccess"));
      cancelOnClick();
    } catch {
      errorToast(t("Next:DocumentManagementSystem:DocumentEdit.PUTError"));
    }
  }

  const onSubmit = handleSubmit(onValid, errorHandler);

  return (
    <DocumentForm
      formMethods={formMethods}
      ref={ref}
      cancelOnClick={cancelOnClick}
      docLibraryId={docLibraryId}
      onSubmit={onSubmit}
      componentName="DocumentEdit"
      isLoading={isLoading}
    />
  );
}
