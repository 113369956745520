import { removeNullAndEmptyStringValuesFromObject } from "core/utils/removeNullAndEmptyStringValuesFromObject";
import { DocumentFormValues } from "../../entities";

export function processDocumentFormValues(
  data: DocumentFormValues,
  parentId?: string
) {
  const {
    isVisibleToShops,
    isDocumentUpload,
    attachments: attachmentsObject,
    translations: translationsObject,
    permissions,
    ...rest
  } = data;
  const attachments =
    (attachmentsObject &&
      Object.values(attachmentsObject)
        .filter(
          ({ fileId, documentLink, fileName }) =>
            documentLink || (fileId && fileName)
        )
        .map((attachment) => {
          const { fileName, fileId, documentLink, ...entry } =
            removeNullAndEmptyStringValuesFromObject(attachment);
          if (isDocumentUpload) {
            return { ...entry, fileName, fileId };
          }
          return { ...entry, documentLink };
        })) ||
    [];
  const translations =
    (translationsObject && Object.values(translationsObject)) || [];
  const dataWithoutNulls = removeNullAndEmptyStringValuesFromObject(rest);
  const submitData = {
    ...dataWithoutNulls,
    permissions,
    attachments,
    translations,
    parentId,
  };
  return submitData;
}
