import { useEffect } from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { FormProvider, useController, useForm } from "react-hook-form";
import { uuid } from "core/utils/uuid";
import { BrandCode, RegionCode } from "core/entities";
import { ComponentSwitcher } from "core/components/ComponentSwitcher";
import { SwitchySwitch } from "core/components/SwitchySwitch";
import { useToaster } from "core/hooks/useToaster";
import { useDepartmentOptions } from "core/hooks/useDepartmentOptions";
import { useRoleOptions } from "core/hooks/useRoleOptions";
import { FixnRoleType } from "resources/APIModel";
import { CorporatePermission } from "features/DMS/entities";
import { ALL_VALUE } from "config";
import { CorporateSelectedPermissionsList } from "./CorporateSelectedPermissionsList";
import { useDMSBrandRegionOptions } from "../useDMSBrandRegionOptions";
import { PermissionMultiSelect } from "../PermissionMultiSelect";
import { SelectOption } from "../entities";

interface FormValues {
  brands: SelectOption[];
  regions: SelectOption[];
  departments: SelectOption[];
  roles: SelectOption[];
  permissionMode: "department" | "role";
}

interface CorporatePermissionManagementProps {
  name: string;
  mode: "library" | "document";
  docLibraryId?: string;
}
export function CorporatePermissionManagement({
  name,
  mode,
  docLibraryId,
}: CorporatePermissionManagementProps) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:CorporatePermissionManagement",
  });
  const { errorToast } = useToaster();

  // this controller will broadcast it's value to the parent form using the name provided in the component props
  const {
    field: { onChange: updateFormPermissions, ref, value: perms },
    fieldState: { error },
    formState: { isSubmitted },
  } = useController({
    defaultValue: [],
    name,
    rules: {
      validate: {
        // checks if the array has entries, otherwise displays the error Message
        // https://react-hook-form.com/api/useform/register
        notEmpty: (value) =>
          (Array.isArray(value) && value.length > 0) ||
          String(t("validationError")),
      },
    },
  });
  const currentPerms = perms as CorporatePermission[];
  // creating a new form Context to handle the permission select inputs i.e. brands, regions, corp permissions
  const defaultValues: FormValues = {
    brands: [],
    regions: [],
    departments: [
      {
        label: t("allDepartments"),
        value: ALL_VALUE,
      },
    ],
    roles: [
      {
        label: t("allRoles"),
        value: ALL_VALUE,
      },
    ],
    permissionMode: "department",
  };

  const formMethods = useForm<FormValues>({ defaultValues, mode: "onChange" });
  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
    getValues,
  } = formMethods;

  const {
    brandOptions,
    regionOptions,
    brandCodes,
    brandRegionMap,
    isLoading: isOptionsLoading,
  } = useDMSBrandRegionOptions(mode, docLibraryId);

  const selectedBrands = watch("brands");

  const selectedBrandCodes = (
    selectedBrands.length === 0 || selectedBrands[0]?.value === ALL_VALUE
      ? brandCodes
      : selectedBrands.map(({ value }) => value)
  ) as BrandCode[];

  const {
    departmentOptions,
    departmentBrandMap,
    isLoading: isDepartmentOptionsLoading,
  } = useDepartmentOptions(selectedBrandCodes);

  // remove invalid departments from selection on brand change
  useEffect(() => {
    if (isDepartmentOptionsLoading) return;
    const selectedDepartments = getValues("departments");
    if (selectedDepartments?.[0]?.value === ALL_VALUE) return;
    const filteredSelectedDepartments = selectedDepartments.filter(
      ({ value }) => departmentOptions.some((dep) => dep.value === value)
    );
    if (filteredSelectedDepartments.length === 0) {
      setValue("departments", defaultValues.departments);
    }
    setValue("departments", filteredSelectedDepartments);
  }, [
    departmentOptions,
    isDepartmentOptionsLoading,
    getValues,
    selectedBrands,
    setValue,
    defaultValues.departments,
  ]);

  const { roleOptions, isLoading: isRoleOptionsLoading } = useRoleOptions(
    FixnRoleType.Corporate
  );
  const permissionMode = watch("permissionMode");

  function onAddPermissions(data: FormValues) {
    const { brands, regions, departments, roles } = data;
    const entries: CorporatePermission[] = [];
    // TODO more full featured error system.
    // expand the selections into individual entries, filtering out invalid selections
    brands.forEach((brand) => {
      regions.forEach((region) => {
        const brandCode = brand.value as BrandCode;
        const regionCode = region.value as RegionCode;
        const isBrandRegionValid =
          brandRegionMap[brandCode]?.includes(regionCode) ||
          brand.value === ALL_VALUE ||
          region.value === ALL_VALUE;

        if (!isBrandRegionValid) return;

        if (permissionMode === "department") {
          departments.forEach((department) => {
            const isDepartmentValid =
              departmentBrandMap &&
              (departmentBrandMap[department.value]?.includes(brand.value) ||
                brand.value === ALL_VALUE ||
                region.value === ALL_VALUE ||
                department.value === ALL_VALUE);

            if (!isDepartmentValid) return;

            const id = uuid();
            const newEntry = {
              brand: brand.value,
              region: region.value,
              departmentName: department.value,
              id,
            };
            if (
              !currentPerms?.some(
                (entry) =>
                  entry.brand === newEntry.brand &&
                  entry.region === newEntry.region &&
                  entry.departmentName === newEntry.departmentName
              )
            ) {
              entries.push(newEntry);
            }
          });
        } else {
          roles.forEach((role) => {
            const id = uuid();
            const newEntry = {
              brand: brand.value,
              region: region.value,
              roleId: role.value,
              id,
            };
            if (
              !currentPerms?.some(
                (entry) =>
                  entry.brand === newEntry.brand &&
                  entry.region === newEntry.region &&
                  entry.roleId === newEntry.roleId
              )
            ) {
              entries.push(newEntry);
            }
          });
        }
      });
    });

    if (!entries.length) {
      errorToast("invalid selection");
      return;
    }
    const newEntries = Array.isArray(perms) ? [...perms, ...entries] : entries;
    reset(defaultValues);
    updateFormPermissions(newEntries);
  }

  function onDelete(id: string) {
    const filteredPerms = currentPerms.filter(
      ({ id: currentId }) => id !== currentId
    );
    updateFormPermissions(filteredPerms);
  }

  return (
    <FormProvider {...formMethods}>
      <Box ref={ref}>
        <Stack spacing={2}>
          <PermissionMultiSelect
            name="brands"
            label={t("brandsSelectLabel")}
            loading={isOptionsLoading}
            loadingText={t("brandLoadingText")}
            options={brandOptions}
          />
          <PermissionMultiSelect
            name="regions"
            label={t("regionsSelectLabel")}
            loadingText={t("regionsLoadingText")}
            loading={isOptionsLoading}
            options={regionOptions}
          />
          <SwitchySwitch
            isPositionOne={permissionMode === "department"}
            positionOneText={t("switchDepartmentText")}
            positionTwoText={t("switchRoleText")}
            switchOnChange={(_, checked) =>
              setValue("permissionMode", checked ? "role" : "department")
            }
          />
          <ComponentSwitcher
            switchPosition={permissionMode === "department"}
            ComponentOne={
              <PermissionMultiSelect
                name="departments"
                loading={isDepartmentOptionsLoading}
                loadingText={t("departmentsLoadingText")}
                label="Departments"
                options={departmentOptions}
              />
            }
            ComponentTwo={
              <PermissionMultiSelect
                name="roles"
                loading={isRoleOptionsLoading}
                loadingText={t("rolesLoadingText")}
                label="Roles"
                options={roleOptions}
              />
            }
          />
          <Button
            type="button"
            variant="contained"
            disabled={!isValid}
            sx={{ alignSelf: "flex-end" }}
            onClick={handleSubmit(onAddPermissions)}
            startIcon={<AddIcon />}
          >
            {t("addPermissions")}
          </Button>
        </Stack>

        <Divider sx={{ my: 2 }} />
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Typography variant="h5">* {t("selectedPermissions")}</Typography>
          <Button
            variant="outlined"
            color="warning"
            disabled={!perms.length}
            startIcon={<RestartAltIcon />}
            onClick={() => updateFormPermissions([])}
          >
            {t("clearPermissions")}
          </Button>
        </Stack>
        <CorporateSelectedPermissionsList
          isError={Boolean(isSubmitted && error)}
          dense
          sx={{ minWidth: "700px" }}
          selectedPermissions={perms}
          onDelete={onDelete}
        />
      </Box>
    </FormProvider>
  );
}
