import { useEffect } from "react";
import clone from "just-clone";
import Typography from "@mui/material/Typography";
import { useAPI } from "core/hooks/useAPI";
import { useAPIPutJSON } from "core/hooks/useAPIPutJSON";
import { useToaster } from "core/hooks/useToaster";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IDocCategoryDto } from "resources/APIModel";
import { arrayToObject } from "core/utils/arrayToObject";
import { CategoryForm, defaultValues } from "./CategoryForm";
import { useCategories, useDMSFormErrorHandler } from "../hooks";
import { ManagePaper } from "./ManagePaper";
import { CategoryFormDetailsSkeleton } from "./CategoryFormDetailsSkeleton";
import { CategoryFormValues } from "../entities";

interface CategoryEditProps {
  cancelOnClick: () => void;
  docLibraryId: string;
  id: string;
  canManageLibraryContents: boolean;
}

export function CategoryEdit({
  cancelOnClick,
  docLibraryId,
  id,
  canManageLibraryContents,
}: CategoryEditProps) {
  const formMethods = useForm<CategoryFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const {
    formState: { isSubmitting },
  } = formMethods;
  const { t } = useTranslation("DocumentManagementSystem");
  const apiPut = useAPIPutJSON();
  const { successToast, errorToast } = useToaster();
  const { mutate: mutateParent } = useCategories({
    mode: "manage",
    parentId: docLibraryId,
    canManageLibraryContents,
  });

  const { errorHandler } = useDMSFormErrorHandler(formMethods, "CategoryForm");
  const { isLoading, isError, data, mutate } = useAPI<IDocCategoryDto>(
    `doc-category/${id}`
  );

  useEffect(() => {
    if (!isLoading && !isError && data) {
      const { translations: translationsArray, ...rest } = clone(data);
      const translations = arrayToObject(
        translationsArray!.map((obj) => ({ ...obj })),
        "transKey"
      );
      formMethods.reset({ translations, ...rest });
    }
  }, [data, isLoading, isError, formMethods]);

  const { handleSubmit } = formMethods;

  async function onValid(submitData: CategoryFormValues) {
    try {
      const { translations: translationObject, ...rest } = submitData;
      const translations = Object.values(translationObject);
      const updatedCategory = await apiPut(`doc-category/${id}`, {
        translations,
        ...rest,
      });
      mutate(updatedCategory);
      mutateParent((categories) => {
        const { items, totalCount } = categories || {};
        const updatedItems = items?.map((category) =>
          category.id === updatedCategory.id ? updatedCategory : category
        ) || [updatedCategory];
        return { items: updatedItems, totalCount: totalCount || 1 };
      });
      successToast(t("Next:DocumentManagementSystem:CategoryEdit.PUTSuccess"));
      cancelOnClick();
    } catch {
      errorToast(t("Next:DocumentManagementSystem:CategoryEdit.PUTError"));
    }
  }

  const onSubmit = handleSubmit(onValid, errorHandler);

  return (
    <>
      <Typography variant="h3" textAlign="center" color="primary.main" my={3}>
        {t("Next:DocumentManagementSystem:CategoryEdit.title")}
      </Typography>
      {isLoading ? (
        <ManagePaper
          sectionTitle={t(
            "Next:DocumentManagementSystem:CategoryForm.detailsTitle"
          )}
        >
          <CategoryFormDetailsSkeleton />
        </ManagePaper>
      ) : (
        <CategoryForm
          cancelOnClick={cancelOnClick}
          onSubmit={onSubmit}
          formMethods={formMethods}
          formType="CategoryForm"
          mode="edit"
          isSubmitting={isSubmitting}
        />
      )}
    </>
  );
}
