import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAPIPostJSON } from "core/hooks/useAPIPostJSON";
import { useMenuItems } from "core/hooks/useMenuItems";
import { useToaster } from "core/hooks/useToaster";
import { DocumentFormValues, IDocDocumentDto } from "features/DMS/entities";
import {
  useDMSFormErrorHandler,
  useInfiniteDocuments,
} from "features/DMS/hooks";
import { DocumentForm, defaultValues } from "./DocumentForm";
import { processDocumentFormValues } from "./processDocumentFormValues";
import { useViewableDMSPermissionOptions } from "../PermissionManagement";

interface DocumentAddProps {
  parentId: string;
  docLibraryId: string;
  cancelOnClick: () => void;
  canManageLibraryContents: boolean;
}

export function DocumentAdd({
  cancelOnClick,
  parentId,
  docLibraryId,
  canManageLibraryContents,
}: DocumentAddProps) {
  const formMethods = useForm<DocumentFormValues>({
    defaultValues,
    mode: "onChange",
  });
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:DocumentAdd",
  });
  const apiPostJSON = useAPIPostJSON();
  const { mutate: mutateManageableList } = useInfiniteDocuments({
    parentId,
    mode: "manage",
    canManageLibraryContents,
  });
  const { mutate: mutateViewableList } = useInfiniteDocuments({
    parentId,
    mode: "view",
    canManageLibraryContents,
  });
  const { isLoading } = useViewableDMSPermissionOptions(docLibraryId);
  const { mutate: mutateMenuItems } = useMenuItems();
  const { successToast, errorToast } = useToaster();
  const { handleSubmit } = formMethods;
  const { errorHandler } = useDMSFormErrorHandler(formMethods, "DocumentForm");

  async function onValidSubmit(data: DocumentFormValues) {
    try {
      const submitData = processDocumentFormValues(data, parentId);

      const newDocument = (await apiPostJSON(
        "doc-document",
        submitData
      )) as IDocDocumentDto;

      mutateManageableList((currentResponses) => {
        const lastResponse = currentResponses?.at(-1);
        if (!currentResponses || !lastResponse)
          return [{ items: [newDocument], totalCount: 1 }];
        if (Array.isArray(lastResponse?.items)) {
          const oldItems = lastResponse.items;
          const newTotalCount = lastResponse.totalCount + 1;
          const newItems = [...oldItems, newDocument];
          return currentResponses.map((res, index, { length }) =>
            index === length - 1
              ? { items: newItems, totalCount: newTotalCount }
              : res
          );
        }
        return [{ items: [newDocument], totalCount: 1 }];
      });
      mutateViewableList();
      mutateMenuItems();
      successToast(t("POSTSuccess"));
      cancelOnClick();
    } catch {
      errorToast(t("POSTError"));
    }
  }

  const onSubmit = handleSubmit(onValidSubmit, errorHandler);

  function clearOnClick() {
    formMethods.reset(defaultValues);
  }
  return (
    <DocumentForm
      isLoading={isLoading}
      componentName="DocumentAdd"
      formMethods={formMethods}
      onSubmit={onSubmit}
      clearOnClick={clearOnClick}
      cancelOnClick={cancelOnClick}
      docLibraryId={docLibraryId}
    />
  );
}
