import { ALL_VALUE } from "config";
import { IDocDocumentPermissionDto } from "resources/APIModel";

export function extractInvalidPermissions(
  permissionSet: IDocDocumentPermissionDto[],
  brandRegionMap: Record<string, string[]>,
  departmentBrandMap: Record<string, string[]>
) {
  return permissionSet.filter(({ brand, region, departmentName, roleId }) => {
    if (!departmentName && !roleId) return false;
    if (brand === ALL_VALUE) return false;
    const brandRegions = (brand && brandRegionMap[brand]) || [];
    const isRegionValid = Boolean(
      region === ALL_VALUE || (region && brandRegions.includes(region))
    );
    // handle role case
    if (roleId) {
      return !isRegionValid;
    }
    const departmentBrands = departmentBrandMap[departmentName!];
    const isDepartmentValid =
      departmentName === ALL_VALUE || departmentBrands?.includes(brand!);
    if (!isDepartmentValid || !isRegionValid) return true;
    return false;
  });
}
