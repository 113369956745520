import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { useAPIDelete } from "core/hooks/useAPIDelete";
import { useNavigate } from "core/hooks/useNavigate";
import { useToaster } from "core/hooks/useToaster";
import { useManageableLibraries } from "features/DMS/hooks";

interface LibraryDeleteProps {
  id: string;
  cancelOnClick: () => void;
}

export function LibraryDelete({
  cancelOnClick = () => {},
  id,
}: LibraryDeleteProps) {
  const { t } = useTranslation("DocumentManagementSystem", {
    keyPrefix: "Next:DocumentManagementSystem:LibraryDelete",
  });
  const { errorToast, successToast } = useToaster();
  const { mutate: mutateManageableLibraries } = useManageableLibraries();
  const apiDelete = useAPIDelete();
  const navigate = useNavigate();

  async function onDeleteCLick() {
    try {
      await apiDelete(`doc-library/${id}`);
      const mutateCallback: Parameters<typeof mutateManageableLibraries>[0] = (
        data
      ) => {
        if (!data || !Array.isArray(data.items))
          return { items: [], totalCount: 0 };
        const newItems = data.items.filter((library) => library.id !== id);
        return { items: newItems, totalCount: newItems.length };
      };
      mutateManageableLibraries(mutateCallback);
      successToast(t("successToastMessage"));
      cancelOnClick();
      navigate("/dms/library");
    } catch {
      errorToast(t("errorToastMessage"));
    }
  }
  return (
    <Box>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("warningMessage")}
      </Typography>
      <Typography
        variant="h5"
        mx="auto"
        mb={4}
        textAlign="center"
        maxWidth="65ch"
      >
        {t("confirmMessage")}
      </Typography>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Button
          variant="outlined"
          color="primary"
          onClick={cancelOnClick}
          size="large"
        >
          {t("cancelButton")}
        </Button>
        <Button
          variant="contained"
          color="error"
          size="large"
          onClick={onDeleteCLick}
        >
          {t("deleteButton")}
        </Button>
      </Stack>
    </Box>
  );
}
