import { brandCodes } from "config";
import { useShopOptions } from "features/DMS/hooks";
import { FixnRoleType } from "resources/APIModel";
import { useDepartmentOptions } from "core/hooks/useDepartmentOptions";
import { useRoleOptions } from "core/hooks/useRoleOptions";
import { useDMSBrandRegionOptions } from "../useDMSBrandRegionOptions";

export function useViewableDMSPermissionOptions(docLibraryId: string) {
  const {
    brandOptions,
    regionOptions,
    brandRegionMap,
    isLoading: isBrandRegionOptionsLoading,
  } = useDMSBrandRegionOptions("document", docLibraryId);

  const {
    departmentBrandMap,
    departmentOptions,
    isLoading: isDepartmentsLoading,
  } = useDepartmentOptions([...brandCodes]);

  const {
    isLoading: isCorporateRoleOptionsLoading,
    roleOptions: corporateRoleOptions,
  } = useRoleOptions(FixnRoleType.Corporate);
  const { roleOptions: shopRoleOptions, isLoading: isShopRoleOptionsLoading } =
    useRoleOptions(FixnRoleType.Shop);

  const {
    shopOptions,
    isLoading: isShopOptionsLoading,
    shopBrandRegionMap,
  } = useShopOptions();

  const isLoading =
    isBrandRegionOptionsLoading ||
    isDepartmentsLoading ||
    isCorporateRoleOptionsLoading ||
    isShopOptionsLoading ||
    isShopRoleOptionsLoading;

  return {
    isLoading,
    brandOptions,
    regionOptions,
    brandRegionMap,
    departmentOptions,
    corporateRoleOptions,
    shopOptions,
    shopRoleOptions,
    departmentBrandMap,
    shopBrandRegionMap,
  };
}
